export const getAuthErrorMessage = (error) => {
  if (!error.code) {
    return "Error desconocido. Intenta nuevamente.";
  }

  switch (error.code) {
    case "auth/invalid-email":
      return "El correo electrónico tiene un formato inválido.";
    case "auth/user-disabled":
      return "La cuenta está deshabilitada. Contacta al administrador.";
    case "auth/user-not-found":
      return "No se encontró un usuario con este correo.";
    case "auth/invalid-credential":
      return "Hay datos incorrectos en las credenciales.";
    case "auth/too-many-requests":
      return "Demasiados intentos fallidos. Intenta más tarde.";
    case "auth/network-request-failed":
      return "Error de red. Verifica tu conexión a internet.";
    default:
      console.log("Error desconocido: ", error);
      return "Ocurrió un error desconocido. Intenta nuevamente.";
  }
};
