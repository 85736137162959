import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import React from "react";
import Login from "./Login";
import Account from "./Account";
import Patients from "./Patients";
import Sidebar from "./components/Sidebar";

function App() {
  return (
    <AuthProvider>
      <Router>
        <ProtectedRoute>
          <Sidebar />
        </ProtectedRoute>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="patients"
            element={
              <ProtectedRoute>
                <Patients />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
