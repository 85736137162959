import React, { useEffect, useState } from "react";
import "./css/Account.css";
import "./css/Global.css";

import getCompleteUserData from "./utils/getUserData";

const Account = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCompleteUserData();
      setUserData(data);
    };

    fetchData();
  }, []);

  return (
    <section className="accountSection">
      <h1>Información de la Cuenta</h1>
      {userData ? (
        <div>
          <p>
            <strong>UID:</strong> {userData.uid}
          </p>
          <p>
            <strong>Nombre:</strong> {userData.name}
          </p>
          <p>
            <strong>Apellido:</strong> {userData.surname}
          </p>
          <p>
            <strong>Correo electrónico:</strong> {userData.email}
          </p>
        </div>
      ) : (
        <p>Cargando información del usuario...</p>
      )}
    </section>
  );
};

export default Account;
