import React from "react";
import { slide as Menu } from "react-burger-menu";
import "../css/Sidebar.css";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <Menu>
      <a className="menu-item" href="/Patients">
        Pacientes
      </a>
      <a className="menu-item" href="/Account">
        Mi Cuenta
      </a>
      <a
        className="menu-item"
        onClick={handleLogout}
        style={{ cursor: "pointer" }}
      >
        Cerrar Sesión
      </a>
    </Menu>
  );
}

export default Sidebar;
