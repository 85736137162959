import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDuTutPTtCTiyS0Dp3x12ibDCdgrfW1-I0",
  authDomain: "patientmanagerreact.firebaseapp.com",
  projectId: "patientmanagerreact",
  storageBucket: "patientmanagerreact.firebasestorage.app",
  messagingSenderId: "931799011030",
  appId: "1:931799011030:web:027fc783979fabfb0c0377",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;
