import { getAuth, onAuthStateChanged } from "firebase/auth";

let cachedUID = null;

const getUserUID = () => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();

    onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          cachedUID = user.uid;
          resolve(cachedUID);
        } else {
          cachedUID = null;
          resolve(null);
        }
      },
      (error) => {
        cachedUID = null;
        reject(error);
      }
    );
  });
};

export default getUserUID;
